import axios from 'axios'
import config from '@/config/config'
import { v4 as uuid } from 'uuid'
// import router from '@/router'

const serverClient = axios.create({
  baseURL: config.api.baseURL,
  headers: config.api.headers
})

serverClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers['X-Request-Id'] = uuid()
  return config
})

serverClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response && error.response.status === 401) {
    //   router.push('/auth/login')
    // }
    return Promise.reject(error)
  }
)

export default serverClient
