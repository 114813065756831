<template>
  <transition
    enter-active-class="transform ease-out duration-300 transition"
    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transition ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <button
      @click="show = false"
      v-if="show"
      :class="[
        'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
        {
          'shadow-blue-100': data.color === 'success',
          'shadow-red-100': data.color === 'error',
          'shadow-gray-100': data.color === 'info'
        }
      ]"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <i
              :class="[
                {
                  'text-green-500': data.color === 'success',
                  'text-red-500': data.color === 'error',
                  'text-gray-500': data.color === 'info'
                },
                'fas text-xl',
                'fa-' + data.icon
              ]"
            />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900 text-left">{{ data.title }}</p>
            <p v-if="data.description != ''" class="mt-1 text-sm text-gray-500 text-left">
              {{ data.description }}
            </p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button
              type="button"
              @click="show = false"
              class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <!-- <icon i="xmark" /> -->
              <i class="fas fa-xmark" />
            </button>
          </div>
        </div>
      </div>
    </button>
  </transition>
</template>

<script>
export default {
  name: 'NotificationComponent',
  data() {
    return {
      show: true
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    href() {
      let href = window.location.origin + this.data.href
      if (this.data.href !== '') {
        window.location.href = href
      } else {
        return null
      }
    }
  }
}
</script>
