import { handleAsync } from '@/utils/errorHandler'
import serverClient from '@/plugins/server'
import AppError from '@/utils/AppError'
import type { ServerResponse } from '@/types/Server'

import BaseService from '@/classes/service'
import type { User } from '@/types/User'

const path = 'v1/users'
const UserService = Object.assign({}, BaseService<User>(path, 'Usuario'), {
  register: async (data: User): Promise<ServerResponse<any>> => {
    const [error, responseData] = await handleAsync(() =>
      serverClient.post(`${path}/register`, data)
    )
    if (error || !responseData) {
      const _ERR = error.response.data.errors || 'Error desconocido'
      const _CODE = error.response.data.code || ''
      throw new AppError(_ERR, _CODE)
    }
    return responseData.data
  },
  login: async (data: Pick<User, 'email' | 'password'>): Promise<ServerResponse<any>> => {
    const [error, responseData] = await handleAsync(() => serverClient.post(`${path}/login`, data))
    if (error || !responseData) {
      const _ERR = error.response.data.errors || 'Error desconocido'
      const _CODE = error.response.data.code || ''
      throw new AppError(_ERR, _CODE)
    }
    // console.log(responseData.data)
    return responseData.data
  },
  logout: async (token: string): Promise<ServerResponse<any>> => {
    const [error, responseData] = await handleAsync(() =>
      serverClient.post(`${path}/logout`, { token })
    )
    if (error || !responseData) {
      const _ERR = error.response.data.errors || 'Error desconocido'
      const _CODE = error.response.data.code || ''
      throw new AppError(_ERR, _CODE)
    }
    return responseData.data
  },
  checkToken: async (token: string): Promise<ServerResponse<any>> => {
    const [error, responseData] = await handleAsync(() =>
      serverClient.post(`${path}/check_token`, { token })
    )
    if (error || !responseData) {
      const _ERR = error.response.data.errors || 'Error desconocido'
      const _CODE = error.response.data.code || ''
      throw new AppError(_ERR, _CODE)
    }
    return responseData.data
  }
})

export default UserService
