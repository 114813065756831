import { handleAsync } from '@/utils/errorHandler'
import { NotifyStore } from '@/stores/Notify'
import { UserStore } from '@/stores/User'
import type {
  SavedModel,
  SingleItem,
  ServerResponse,
  MultipleItems,
  CountItems,
  PaginateItems
} from '@/types/Server'
import type { User, SavedUser } from '@/types/User'
import BaseController from '@/classes/controller'
import UserService from '@/services/user.service'
import router from '@/router'

type LoginData = {
  token: string
  user: SavedUser
}

const UserController = Object.assign({}, BaseController<User>(UserService), {
  register: async (data: User): Promise<ServerResponse<any>> => {
    const notify = NotifyStore()
    const [error, responseData]: [Error | null, ServerResponse<any> | null] = await handleAsync(
      () => UserService.register(data)
    )
    if (error || !responseData) {
      notify.error(`Error al registrar ${UserService.resource}`, error?.message)
      throw new Error(error?.message)
    }
    notify.success(`${UserService.resource} registrado correctamente`)
    return responseData
  },
  login: async (data: Pick<User, 'email' | 'password'>): Promise<ServerResponse<LoginData>> => {
    const notify = NotifyStore()
    const store = UserStore()
    const [error, responseData]: [Error | null, ServerResponse<LoginData> | null] =
      await handleAsync(() => UserService.login(data))
    if (error || !responseData) {
      notify.error(`Error al iniciar sesión en ${UserService.resource}`, error?.message)
      throw new Error(error?.message)
    }
    store.token = responseData.data.token
    console.log('store.user', store.user)
    store.user = responseData.data.user
    notify.success(`Sesión iniciada correctamente`)
    return responseData
  },
  logout: async (): Promise<ServerResponse<any>> => {
    const notify = NotifyStore()
    const store = UserStore()
    const [error, responseData]: [Error | null, ServerResponse<any> | null] = await handleAsync(
      () => UserService.logout(store.token)
    )
    if (error || !responseData) {
      notify.error(`Error al cerrar sesión en ${UserService.resource}`, error?.message)
      throw new Error(error?.message)
    }
    store.token = ''
    store.user = null
    router.push({ name: 'Inicia Sesión' })
    notify.success(`Sesión cerrada correctamente`)

    return responseData
  },
  checkJwt: async (token: string): Promise<ServerResponse<any>> => {
    // const notify = NotifyStore()
    const [error, responseData]: [Error | null, ServerResponse<any> | null] = await handleAsync(
      () => UserService.checkToken(token)
    )
    if (error || !responseData) {
      // notify.error(`Error al verificar sesión en ${UserService.resource}`, error?.message)
      throw new Error(error?.message)
    }
    // notify.success(`Sesión verificada correctamente`)
    return responseData
  }
})

export default UserController
