import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

import tooltipDirective from './directives/tooltipDirective'
import checkingDirective from './directives/checkingDirective'

import App from './App.vue'
import router from './router/'

import './assets/css/tailwind.css'
import './assets/css/tooltip.css'
import './assets/fontawesome/css/all.min.css'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://17a98d0a277114e95efc09073077158d@o4508123211235328.ingest.us.sentry.io/4508123270283265',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0 // Profile 100% of the transactions. This value is relative to tracesSampleRate
})

app.directive('tooltip', tooltipDirective)
app.directive('checking', checkingDirective)

app.use(createPinia())
app.use(router)

app.mount('#app')
