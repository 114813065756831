import { handleAsync } from '@/utils/errorHandler'
import { NotifyStore } from '@/stores/Notify'
import type { Saved, PaginateOptions } from '@/types/Responses'
import type {
  SingleItem,
  ServerResponse,
  MultipleItems,
  CountItems,
  PaginateItems
} from '@/types/Server'

const BaseController = <T>(service: any) => {
  return {
    get: async (
      id: string,
      populate: string[] = [],
      select: string[] = []
    ): Promise<SingleItem<Saved<T>>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<SingleItem<Saved<T>>> | null] =
        await handleAsync(() => service.get(id, populate, select))
      if (error && !responseData) {
        notify.error(`Error al obtener ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} obtenido correctamente`)
      return responseData?.data as SingleItem<Saved<T>>
    },
    find: async (
      query: any,
      populate: string[] = [],
      select: string[] = []
    ): Promise<MultipleItems<Saved<T>>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<MultipleItems<Saved<T>>> | null] =
        await handleAsync(() => service.find(query, populate, select))
      if (error) {
        notify.error(`Error al buscar ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} encontrado correctamente`)
      return responseData?.data as MultipleItems<Saved<T>>
    },
    findOne: async (query: any): Promise<SingleItem<Saved<T>>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<SingleItem<Saved<T>>> | null] =
        await handleAsync(() => service.findOne(query))
      if (error) {
        notify.error(`Error al buscar ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} encontrado correctamente`)
      return responseData?.data as SingleItem<Saved<T>>
    },
    create: async (data: T): Promise<SingleItem<Saved<T>>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<SingleItem<Saved<T>>> | null] =
        await handleAsync(() => service.create(data))
      if (error) {
        notify.error(`Error al crear ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.success(`${service.resource} creado correctamente`)
      return responseData?.data as SingleItem<Saved<T>>
    },
    createMany: async (data: T[]): Promise<MultipleItems<Saved<T>>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<MultipleItems<Saved<T>>> | null] =
        await handleAsync(() => service.createMany(data))
      if (error) {
        notify.error(`Error al crear ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} creado correctamente`)
      return responseData?.data as MultipleItems<Saved<T>>
    },
    update: async (id: string, data: T): Promise<SingleItem<Saved<T>>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<SingleItem<Saved<T>>> | null] =
        await handleAsync(() => service.update(id, data))
      if (error) {
        notify.error(`Error al actualizar ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.success(`${service.resource} actualizado correctamente`)
      return responseData?.data as SingleItem<Saved<T>>
    },
    delete: async (id: string): Promise<SingleItem<Saved<T>>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<SingleItem<Saved<T>>> | null] =
        await handleAsync(() => service.delete(id))
      if (error) {
        notify.error(`Error al eliminar ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.success(`${service.resource} eliminado correctamente`)
      return responseData?.data as SingleItem<Saved<T>>
    },
    count: async (query: any): Promise<CountItems> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<CountItems> | null] =
        await handleAsync(() => service.count(query))
      if (error) {
        notify.info(`Error al contar ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} contado correctamente`)
      return responseData?.data as CountItems
    },
    distinct: async (key: string, query: any): Promise<string[]> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<{ items: string[] }> | null] =
        await handleAsync(() => service.distinct(key, query))
      if (error) {
        notify.error(`Error al obtener ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} obtenido correctamente`)
      return responseData?.data.items as string[]
    },
    paginate: async (options: PaginateOptions): Promise<PaginateItems<Saved<T>>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<PaginateItems<Saved<T>>> | null] =
        await handleAsync(() => service.paginate(options))
      if (error) {
        notify.error(`Error al paginar ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} paginado correctamente`)
      return responseData?.data as PaginateItems<Saved<T>>
    },
    keys: async (): Promise<ServerResponse<{ keys: string[] }>> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<{ keys: string[] }> | null] =
        await handleAsync(() => service.keys())
      if (error) {
        notify.error(`Error al obtener ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} obtenido correctamente`)
      return responseData as ServerResponse<{ keys: string[] }>
    },
    aggregate: async (pipeline: {
      settings: { ids?: string[]; dtes?: string[] }
      data: any[]
    }): Promise<{ data: any }> => {
      const notify = NotifyStore()
      const [error, responseData]: [Error | null, ServerResponse<any> | null] = await handleAsync(
        () => service.aggregate(pipeline)
      )
      if (error) {
        notify.error(`Error al agregar ${service.resource}`, error.message)
        throw new Error(error.message)
      }
      notify.info(`${service.resource} agregado correctamente`)
      return responseData?.data
    }
  }
}

export default BaseController
