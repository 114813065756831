const config = {
  api: {
    baseURL: 'https://na7j1hn12i.execute-api.us-east-1.amazonaws.com/production/',
    // baseURL: import.meta.env.VITE_BACKEND_ENDPOINT,
    // baseURL: 'http://192.168.1.86:3000/',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  appwrite: {
    endpoint: import.meta.env.VITE_APPWRITE_ENDPOINT,
    project: import.meta.env.VITE_APPWRITE_PROJECT
  }
}

export default config        
