<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-[999999]"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <NotificationComponent v-for="(i, index) in notify.notifications" :key="index" :data="i" />
    </div>
  </div>
</template>

<script lang="ts">
import NotificationComponent from '@/ui/components/NotificationComponent.vue'
import { NotifyStore } from '@/stores/Notify'

export default {
  components: {
    NotificationComponent
  },
  data() {
    return {
      notify: NotifyStore()
    }
  }
}
</script>
