// checkingDirective.ts
export default {
  mounted(el: HTMLInputElement, binding: any) {
    const type = binding.value // email | dni | phone
    if (type === 'email') {
      el.addEventListener('input', () => {
        console.log('email')
        const value = el.value
        const newValue = value.trim().toLowerCase()
        el.value = newValue
        el.type = 'text'
        el.setSelectionRange(newValue.length, newValue.length)
        el.type = 'email' // Restaurar el tipo original
      })
    }
  }
}
