import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'

export const NotifyStore = defineStore('NotifyStore', {
  state: () => ({
    notifications: [] as any[],
    el: ''
  }),
  getters: {},
  actions: {
    del(id: string, time: number) {
      setTimeout(() => {
        this.notifications.splice(this.notifications.indexOf(id), 1)
      }, time * 1000)
    },
    addNotification(
      type: string,
      icon: string,
      color: string,
      title: string,
      description = '',
      time = 3
    ) {
      const data = {
        id: uuidv4(),
        type: type,
        icon: icon,
        title: title,
        description: description,
        time: time,
        color: color,
        href: '#'
      }
      this.notifications.unshift(data)
      this.del(data.id, data.time)
      return data
    },
    success(title: string, description = '', time = 3) {
      return this.addNotification('success', 'circle-check', 'success', title, description, time)
    },
    info(title: string, description = '', time = 3) {
      return
      if (import.meta.env.PROD) return
      return this.addNotification('info', 'clipboard-check', 'info', title, description, time)
    },
    warning(title: string, description = '', time = 3) {
      return this.addNotification('warning', 'alert', 'info', title, description, time)
    },
    error(title: string, description = '', time = 3) {
      return this.addNotification('error', 'circle-xmark', 'error', title, description, time)
    }
  }
})
