type AsyncFunction<T> = () => Promise<T>

export async function handleAsync<T>(fn: AsyncFunction<T>): Promise<[any, null | T]> {
  try {
    const result = await fn()
    return [null, result]
  } catch (error) {
    return [error, null]
  }
}
