// BaseService.ts

import { handleAsync } from '@/utils/errorHandler'
import serverClient from '@/plugins/server'
import AppError from '@/utils/AppError'
import type {
  Saved,
  ServerSingleSaved,
  ServerMultipleSaved,
  ServerCountSaved,
  PaginateSaved,
  PaginateOptions
} from '@/types/Responses'
import type { ServerResponse } from '@/types/Server'

const BaseService = <T extends object>(path: string, resource: string | null | undefined) => {
  return {
    resource: resource ?? path,
    get: async (
      id: string,
      populate: string[] = [],
      select: string[] = []
    ): Promise<ServerSingleSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.post(`${path}/get/${id}`, {
          populate,
          select
        })
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    find: async (
      query: any,
      populate: string[] = [],
      select: string[] = []
    ): Promise<ServerMultipleSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.post(`${path}/find`, { query, populate, select })
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    findOne: async (query: any): Promise<ServerSingleSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.get(`${path}/find/one`, { params: query })
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    create: async (data: T): Promise<ServerSingleSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.post(`${path}/create`, data)
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    createMany: async (data: T[]): Promise<ServerMultipleSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.post(`${path}/create/many`, data)
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    update: async (id: string, data: T): Promise<ServerSingleSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.put(`${path}/update/${id}`, data)
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    delete: async (id: string): Promise<ServerSingleSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.delete(`${path}/delete/${id}`)
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    count: async (query: any): Promise<ServerCountSaved> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.post(`${path}/count`, query)
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    distinct: async (key: string, query: any): Promise<ServerMultipleSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.post(`${path}/distinct`, { key, query })
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    paginate: async (options: PaginateOptions): Promise<PaginateSaved<T>> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.post(`${path}/paginate?page=${options.page}&items=${options.itemsPerPage}`, {
          query: options.query,
          sort: options.sort,
          populate: options.populate,
          select: options.select
        })
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    keys: async (): Promise<ServerResponse<{ keys: string[] }>> => {
      const [error, responseData] = await handleAsync(() => serverClient.get(`${path}/keys`))
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    },
    aggregate: async (pipeline: any): Promise<any> => {
      const [error, responseData] = await handleAsync(() =>
        serverClient.post(`${path}/agreggate`, { pipeline })
      )
      if (error || !responseData) {
        const _ERR = error.response.data.errors || 'Error desconocido'
        const _CODE = error.response.data.code || ''
        throw new AppError(_ERR, _CODE)
      }
      return responseData.data
    }
  }
}

export default BaseService
