import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import type { SavedUser } from '@/types/User'

export const UserStore = defineStore('UserStore', {
  state: () => ({
    token: useStorage('token', ''),
    user: useStorage(
      'user',
      {} as SavedUser,
      localStorage,
      { mergeDefaults: true } // <--
    )
  }),
  getters: {
    // user: (state) => JSON.parse(state._user)
  },
  actions: {
    setUser(user: SavedUser) {
      this.user = user
    }
  }
})
