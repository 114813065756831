// tooltipDirective.ts
export default {
  beforeMount(el: HTMLElement, binding: any) {
    const tooltipText = binding.value

    const tooltip = document.createElement('div')
    tooltip.className = 'tooltiptext'
    tooltip.textContent = tooltipText

    // Tooltip arrow
    const arrow = document.createElement('div')
    arrow.className = 'tooltip-arrow'
    tooltip.appendChild(arrow)

    el.classList.add('tooltip')
    el.appendChild(tooltip)

    el.addEventListener('mouseenter', () => {
      tooltip.style.visibility = 'visible'
    })

    el.addEventListener('mouseleave', () => {
      tooltip.style.visibility = 'hidden'
    })
  }
}
